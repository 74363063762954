// .modal-dialog {
//   position: absolute;
//   margin: 0;
//   bottom: 0;
//   width: 100%;
// }

// .modal-header {
//   border-bottom: none;
//   padding-bottom: 0;
//   .modTitle {
//     line-height: 115%;
//     font-size: 18px;
//     text-transform: lowercase;
//     font-weight: 500;
//     color: color(text-dark);
//   }
// }

.modal-body {
  // margin-bottom: 3rem;
}

//modal wrapper here

.adderWrapper {
  .adderItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    border-bottom: 1px solid rgb(247, 247, 247);
    margin-bottom: 1rem;
  }

  &-name {
  }
}

//quantity switch

.quantityAdder {
  display: flex;
  color: white;

  .reducer {
    border-radius: 4px;
    height: 2.5rem;
    width: 2.5rem;
    @include flex-center;
    font-size: 2rem;
    background: rgb(226, 72, 72);
  }
  .status {
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1.5rem;
    font-weight: 300;
    @include flex-center;
    color: color(text-dark);
  }
  .adder {
    font-size: 1.5rem;
    border-radius: 4px;

    height: 2.5rem;
    width: 2.5rem;
    @include flex-center;
    background: rgb(73, 207, 91);
  }
}
