.comboWrapper {
  // padding: 1rem;

  &-title {
    color: color(primary, base);
    text-transform: uppercase;
  }
  &-image {
    border-radius: 8px;
    height: 36vh;
    width: 100%;
    object-fit: cover;
  }
}

//caresoul options

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.carousel-indicators {
  button {
    width: 20px !important;
    height: 2.5px !important;
  }
  li {
    height: 2px;
    width: 15px;
    overflow: hidden;
  }
}

// .carousel-indicators [data-bs-target] {
//   width: 20px !important;
//   height: 2.5px !important;
// }
