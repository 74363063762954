.homeWrapper {
  .imageholder {
    &-cover {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      background: #000;
      position: relative;
      height: 20vh;
      margin: 0;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      &-dp {
        border: 3px solid rgb(230, 230, 230);

        position: absolute;
        bottom: 0px;
        left: 0px;
        transform: translate(0, 70%);
        height: 115px;
        width: 115px;
        border-radius: calc(115px);
        overflow: hidden;
        img {
          height: 115px;
          width: 115px;

          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  &-address {
    margin-left: 35%;
    &-title {
      color: color(text-dark);
    }
    &-place {
      color: color(text-dark);
      font-size: 14px;
    }
  }

  &-buttons {
    padding: 0 1rem;

    &-menu {
      text-align: center;
    }
    &-offers {
      text-align: center;
      margin-top: 1rem;
    }
  }
  &-powered {
    color: #33bcaa;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 3px;
    font-style: italic;
    font-size: 10px;
  }
}

// offers design here

.offerWrapper {
  margin: 2rem 0 1rem 0;
  padding: 0 0.5rem;

  &-imaginfo {
    position: relative;
    border: 1px solid #afa9a95c;
    border-radius: 8px;

    &-holder {
      height: 36vh;
      overflow: hidden;
      margin: 0;
    }
    &-label {
      position: absolute;
      top: 5%;
      left: -2%;
      display: block;
      color: white;
      background: green;
      padding: 0 0.5rem;
      border-radius: 4px;
    }
  }
}
