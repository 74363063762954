.SliderHolder {
  position: sticky;
  padding: 1rem;
  text-align: center;
  display: flex;
  overflow-y: scroll;
  &-items {
    margin-right: 1rem;

    width: 90px;
    &-image {
      margin: 0;
      img {
        width: 70px;
        height: 70px;
        border-radius: calc(70px);
        overflow: hidden;
        object-fit: cover;
        // border: 1px solid color(secondary, base);
        background: color(secondary, base);
      }
      figcaption {
        text-align: center;
        font-size: 13px;
        margin-top: 0.3rem;
        line-height: 120%;
      }
    }
  }
}

//menu lists section

.menuHoldder {
  padding: 1rem;
  padding-top: 0;
  background: #f8f8f8;
  h1 {
    text-transform: uppercase;
    font-size: 16px !important;
    padding: 0.3rem 0;
    color: color(primary, base);
  }
}

//menubox

.ItemBox {
  display: flex;
  background: white;
  // box-shadow: 1px 2px 10px rgb(209, 209, 209);
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px;
  overflow: hidden;
  max-height: 107px;
  margin-bottom: 0.7rem;
  &-image {
    img {
      width: 90px;
      height: 100%;
      object-fit: cover;
    }
  }

  &-desc {
    margin-left: 0.7rem;
    margin-bottom: 0.3rem;
    overflow: hidden;
    &-title {
      line-height: 115%;
      color: color(test-dark-black);
      font-weight: 600;
      margin-bottom: 0;
      padding: 0 !important;
      margin-top: 0.3rem;
    }
    &-desc {
      color: color(text-light);
      font-size: 10px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 0.3rem;
      margin-bottom: 0.3rem;
      // background: #000;
    }

    &-pricebox {
      display: flex;
      overflow-x: scroll;
      .itemPrice {
        margin-right: 0.3rem;
        padding-right: 0.5rem;
        border-right: 1px solid rgba(180, 180, 180, 0.507);

        &-name {
          text-align: right;
          margin-top: 0;
          padding-top: 0;
          line-height: 1rem;
          font-size: 14px;
        }
        &-price {
          &-rs {
            font-size: 10px;
          }
          &-amt {
            font-weight: 800;
            font-size: 18px;
            color: color(test-dark-black);
          }
        }
      }
    }
  }
}

.Stickybar {
  position: sticky;
  top: 40px;
  background: white;
}
