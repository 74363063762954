.resultsPage {
  padding: 1rem;
  h6 {
    margin-bottom: 1rem;
    font-size: 14px;
  }
  //search results page item css not that of list style
  .ItemBox-desc-title {
    color: color(primary, base);
  }
}
