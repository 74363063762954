.LoaderWrapper {
  border-radius: 4px;

  position: relative;
  overflow: hidden;
  background: rgb(211, 211, 211);

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      #e4e4e4 50%,
      transparent 100%
    );
    animation: load 0.8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

//home skeleton
.HomeSk-hero {
  height: 20vh;
}

.HomeSk-offers {
  height: 25vh;
  margin-top: 3rem;
}

.HomeSk-buttons {
  height: 8vh;
  width: 90%;
  margin: 3rem auto;
}

.HomeSk-powered {
  position: absolute;
  height: 2vh;
  width: 100%;
  margin: 1rem auto;
  bottom: 1rem;
}

//list skeletons

.liskSk-circles {
  display: flex;
  &-items {
    width: 60px;
    height: 60px;
    border-radius: calc(60px);
    margin: 1rem 0.5rem;
    overflow: hidden;
  }
}

.liskSk-titles {
  margin-top: 1rem;
  height: 1.2rem;
  width: 30%;
}

.liskSk-lists {
  height: 5rem;
  margin-top: 1rem;
  width: 85%;
}

//category skeletons
.catSK-titles {
  margin-left: 1rem;
  height: 1.2rem;
  width: 30%;
}

.catSK-rect {
  display: flex;
  flex-wrap: wrap;

  &-items {
    margin-top: 1rem;
    margin-left: 1rem;

    width: 28%;
    height: 6rem;
  }
}
