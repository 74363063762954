.searchWrapper {
  padding: 0.5rem 1rem;

  &-box {
    position: relative;
    display: flex;
    justify-content: space-between;

    .chipsWrapper {
      background: rgb(255, 255, 255);
      border: 0.5px solid color(primary, base);
      width: 100%;
      position: absolute;
      padding: 0.5rem;
      z-index: 99;

      border-radius: 4px;
      top: 120%;
      left: 0;
      .chipBox {
        display: flex;
        flex-wrap: wrap;

        &-chip {
          border: 1px solid color(primary, base);
          margin-right: 0.5rem;
          padding: 0.5rem 1.5rem;
          border-radius: 200px;
          margin-top: 1rem;
          color: color(primary, base);

          &:hover {
            background: color(primary, base);
            color: white;
          }
        }
      }

      .chipCancel {
        position: absolute;
        right: 8px;
        top: 0px;
        color: color(text-light);
        font-size: 20px;
      }
    }

    .SearchForm {
      &-result {
        background-color: white;
        max-height: 15rem;

        overflow-y: scroll;
        position: absolute;
        width: 71%;
        border-radius: 0 0 4px 4px;
        box-shadow: 1px 3px 6px rgba(156, 156, 156, 0.39);

        p {
          padding: 0.3rem 0.5rem;
          font-size: 14px;

          color: color(text-light);
          margin-bottom: 0rem;
        }
      }
    }
  }
}

.catgroeyHolder {
  padding: 0.5rem 1rem;
  .catgroryTitle {
    text-transform: uppercase;
    color: color(primary, base);
  }
  hr {
    margin: 0 0 1rem 0;
  }
  &-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-column {
      width: 31%;
      overflow: hidden;
      margin-bottom: 0.7rem;
    }
  }
}

.catbox {
  &-imageholder {
    margin: 0;
    img {
      border-radius: 8px;
      border: 0.5px solid rgb(224, 224, 224);
      overflow: hidden;
      height: 6rem;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    figcaption {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      margin-top: 0.5rem;
    }
  }
}

//search styling

.InputhWrapper {
  &-box {
    padding: 0.3rem;
    border: 5px solid black;
  }
}
