.cartHolder {
  .cartHeader {
    border-bottom: 1px solid rgba(206, 206, 206, 0.637);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    &-items {
      font-weight: 500;
      font-size: 18px;
      color: color(primary, base);
    }

    &-icon {
      color: color(danger, base);
    }
  }
  .cartItems {
    padding: 0 1rem;

    &-box {
      margin-top: 0.5rem;
      color: color(text-light);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
    }
    &-icon {
      color: color(primary, base);
      padding: 0.4rem 0.7rem;
      font-size: 20px;
      @include flex-center;
    }
  }

  .PlaceOrder {
    @include flex-center;
    color: white;
    background: color(primary, base);
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 3rem;
  }
}

//cart button

.cartWrapper {
  background: color(primary, base);
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 3rem;
  &-nos {
    position: relative;
    &-state {
      position: absolute;

      left: 0;
    }
  }
}

//edit cart

.editCart {
  display: flex;
  flex-direction: column;
  align-items: center;

  .editTitle {
    font-size: 14px;
    // text-transform: lowercase;
    text-align: center;
  }
  .deleteItem {
    color: color(danger, base);
    padding: 1.5rem;
  }
  .editSwitch {
    margin-bottom: 3rem;
  }
  .saveItems {
    background: color(primary, base);
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    @include flex-center;
    height: 3rem;
    color: white;
  }
}

//success modal

.successHolder {
  display: flex;
  flex-direction: column;
  @include flex-center;

  &-icon {
    font-size: 4rem;
    color: color(success, base);
  }

  &-text {
    margin-bottom: 3rem;
  }
  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #1f7dc2;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 3rem;
  }
}
