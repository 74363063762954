svg:not(:root) {
  overflow: unset;
}
html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}
body {
  margin: 0;
  font-weight: $font-regular;
  background: #f8f8f8;
  font-family: 'Roboto', sans-serif;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
  color: color(text-dark);
  font-family: 'Poppins', sans-serif;
  line-height: 114%;
}
h1 {
  font-size: $h1 !important;
  font-weight: $font-semi-bold;
}
h2 {
  font-size: $h2;
  font-weight: $font-bold;
}
h3 {
  font-size: $h3;
  font-weight: $font-bold;
}
h4 {
  font-size: $h4;
}

p {
  margin-top: 0;
  line-height: 170%;
  font-size: $font-regular;
}
button,
textarea,
input {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

a {
  text-decoration: none !important;
  color: unset;
  &:hover {
    color: none;
  }
}

ul {
  list-style: none;
  padding: 0;
}

.clearfix {
  &:before,
  &:after {
    display: table;
    content: '';
  }
  &:after {
    clear: both;
  }
}
